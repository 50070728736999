<template>
  <section>
    <div class="content-header">
      <h2>Nine strategies to persist during your job search </h2>
    </div>
    <div class="content-wrapper">
      <p>The career journey can be challenging and exhausting. Self-care is important. Centennial College suggests nine strategies for students to implement to help them persist during a job search:</p>
      <div class="carousel">
        <hooper
          ref="carousel_01"
          class="mb-4"
          :wheelControl="false"
        >
          <slide>
            <div class="slide">
              <div>
                <p>1. When you start worrying about future events, bring yourself back to the present</p>
              </div>
            </div>
          </slide>

          <slide>
            <div class="slide">
              <div>
                <p>2. Notice and name your feelings and thoughts</p>
              </div>
            </div>
          </slide>

          <slide>
            <div class="slide">
              <div>
                <p>3. Find support systems—cheerleaders, communities and services</p>
              </div>
            </div>
          </slide>

          <slide>
            <div class="slide">
              <div>
                <p>4. Focus attention on what you can control</p>
              </div>
            </div>
          </slide>

          <slide>
            <div class="slide">
              <div>
                <p>5. Have a bias to action </p>
              </div>
            </div>
          </slide>

          <slide>
            <div class="slide">
              <div>
                <p>6. Change your relationship to failure</p>
              </div>
            </div>
          </slide>
          <slide>
            <div class="slide">
              <div>
                <p>7. Set small, manageable goals</p>
              </div>
            </div>
          </slide>
          <slide>
            <div class="slide">
              <div>
                <p>8. Be flexible, adaptable and open-minded about opportunities</p>
              </div>
            </div>
          </slide>
          <slide>
            <div class="slide">
              <div>
                <p>9. Look after yourself—it’s a long hike, not a sprint!</p>
              </div>
            </div>
          </slide>
          <hooper-navigation slot="hooper-addons">
            <img
              slot="hooper-prev"
              src="@/assets/img/_ui/svg/carousel-back.svg"
              alt="previous"
              srcset=""
            >
            <img
              slot="hooper-next"
              src="@/assets/img/_ui/svg/carousel-next.svg"
              alt="next"
              srcset=""
            >
          </hooper-navigation>

          <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
      </div>
      <p class="pt-2">You can learn more about these strategies in <a
          :href="`${publicPath}docs/career/Handout - Job Search Uncertainty Apr 2021.pdf`"
          download
        >this helpful resource</a> (used with permission from Tracey Lloyd).</p>
      <a
        :href="`${publicPath}docs/career/Handout - Job Search Uncertainty Apr 2021.pdf`"
        download>
        </a>
      <p class="lm">Learn more</p>
      <p>Here are some additional resources to support mental health and wellness in the workplace:</p>
      <ul>
        <li><a
            href="https://cmha.ca/find-info/mental-health/check-in-on-your-mental-health/work-life-balance-quiz/#balance-quiz"
            target="_blank"
          >How is Your Work-Life Balance?</a> (quiz)</li>
        <li><a
            href="https://cmha.ca/find-info/mental-health/check-in-on-your-mental-health/whats-your-stress-index/"
            target="_blank"
          >What's Your Stress Level?</a> (quiz)</li>
      </ul>
      <p>The <router-link to="/students-resilience/personal">personal resilience</router-link> section of this toolkit also provides some excellent resources to help you support your students’ mental health and wellness.</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination
} from 'hooper'
import 'hooper/dist/hooper.css'

export default {
  name: '08',
  components: {
    // SidebarMenu,
    // Accordion,
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
